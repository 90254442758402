
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState, Fragment } from 'react';
import { Button, message, Input, Select } from 'antd';
import './index.css';
import creService from "../../services/creService";
import { toast } from "react-toastify";

const getCurrentStep = (path, questions) => {
    let finishStep
    for (let key of path) {
        if (finishStep) {
            finishStep = finishStep[key]
        } else {
            finishStep = questions[key]
        }
    }
    return finishStep
}

const getNextStep = (currentPath, questions, answerSubkey, moveBack) => {
    if (!currentPath.length) {
        return currentPath
    }
    const currentQuestion = getCurrentStep(currentPath, questions)
    if (moveBack) {
        const backpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] - 1]
        const priviousQuestion = getCurrentStep(backpath, questions)
        if (priviousQuestion) {
            return backpath
        } else {
            return backpath.slice(0, -2)
        }

    } else {
        if (answerSubkey) {
            if (currentQuestion[answerSubkey]) {
                return [...currentPath, answerSubkey, 0]
            }
            if (currentQuestion.sub) {
                return [...currentPath, 'sub', 0]
            }
        }


        const nextpath = [...currentPath.slice(0, -1), currentPath[currentPath.length - 1] + 1]

        const nextQuestion = getCurrentStep(nextpath, questions)
        if (nextQuestion) {
            return nextpath
        } else {
            return getNextStep(currentPath.slice(0, -2), questions)
        }

    }
}



const QuestionDisplayInterface = ({ questionBlocks, questionSaveHandler, savedQuestionAnswers = [], bin }) => {

    const [questions, setQuestions] = useState();
    const [currentPath, setCurrentPath] = useState([]);
    const [currentStep, setCurrentStep] = useState({});
    const [loaded, setLoaded] = useState(0);
    const [block, setBlock] = useState(false);


    useEffect(() => {

        if (currentPath.length) {
            setCurrentStep(getCurrentStep(currentPath, questions))
        }


    }, [currentPath, questions])

    useEffect(() => {

        setQuestions(questionBlocks)


    }, [questionBlocks])

    const next = async (answer) => {

        setBlock(true)

        if (typeof answer === 'object') {


            if (answer.files[0] && bin) {

                const data = new FormData();
                data.append('file', answer.files[0]);
                data.append('binFile', bin);
                data.append('source', 'Violation');


                try {
                    const response = await creService.uploadDocument(data, {
                        onUploadProgress: (ProgressEvent) => {
                            setLoaded(() => (ProgressEvent.loaded / ProgressEvent.total) * 100)
                        },
                    })
                    setLoaded(0)
                    if (response.data.status) {
                        answer = response.data.key
                    } else {
                        toast.error(response.data.message);
                    }
                } catch (e) {
                    toast.error(e.message);
                }
                //
                // here save logic to s3 
            } else {
                answer = ''
            }
        }
        const currentQuestion = getCurrentStep(currentPath, questions)

        if (answer) {
            currentQuestion.resultAnswer = answer
            setQuestions([...questions]) // to trigger update
        }
        const answerSubkey = ((answer || currentQuestion.resultAnswer) + 'Sub')
        const nextPath = getNextStep(currentPath, questions, answerSubkey)
        if (nextPath.length > 1) {

            setCurrentPath(nextPath)
        } else {


            message.success('Processing complete!')
            if (questionSaveHandler) {
                await questionSaveHandler(questions[currentPath[0]])
            }
        }
        setBlock(false)

    };

    const prev = () => {
        const backPath = getNextStep(currentPath, questions, undefined, true)

        if (backPath.length > 1) {
            setCurrentPath(backPath)
        } else {
            message.error('No privious questions!')
        }

    };


    return (
        questions ?
            <>
                <div className='d-flex justify-content-center w-100' >

                    <div className='w-100' >
                        <Select
                            defaultValue={currentPath[0]}
                            placeholder='select question block'
                            style={{ fontSize: '21px', minWidth: '300px' }}
                            onChange={(value) => { setCurrentPath([value, 'questions', 0]) }}
                            options={questions.map((value, i) => {

                                const allreadyDone = savedQuestionAnswers.some(el => el.questionBlockID === value._id)
                                return {
                                    value: i,
                                    label: value.blockName + (allreadyDone ? ' (Done)' : ''),
                                    disabled: !value.questions.length || allreadyDone
                                }
                            }
                            )}
                        />

                        <div className="steps-content d-flex align-items-center justify-content-center flex-column">

                            <h3>{currentStep.question}</h3>
                            <div className='mt-3 w-100 p-2'>
                                {currentStep.answers?.map((answer, i) => {
                                    if (answer === 'client input') {
                                        return <Input.Group compact key={currentStep.question + i}>
                                            <Input key={currentStep.question + i} style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} />
                                            <Button disabled={block} type="primary" onClick={(event) => next(event.target.parentElement.parentElement.children[0].value)} >Submit</Button>
                                        </Input.Group>
                                    } else if (answer === 'client date') {
                                        return <Input.Group compact key={currentStep.question + i}>
                                            <Input key={currentStep.question + i} style={{ width: 'calc(100% - 200px)' }} defaultValue={currentStep.resultAnswer} type="date" />
                                            <Button disabled={block} type="primary" onClick={(event) => next(event.target.parentElement.parentElement.children[0].value)} >Submit</Button>
                                        </Input.Group>
                                    } else if (answer === 'client upload') {
                                        return <Fragment key={currentStep.question + i}>
                                            <Input key={currentStep.question + i} type="file" />
                                            {loaded !== 0 && <div className="progress mt-2">
                                                <div
                                                    className={`progress-bar progress-bar-striped progress-bar-animated bg-info`}
                                                    role="progressbar"
                                                    style={{ width: `${loaded}%` }}
                                                >
                                                    {`Uploading...`}
                                                </div>
                                            </div>
                                            }
                                            <Button disabled={loaded || block} className='mt-2' type="primary" onClick={(event) => next(event.target.parentElement.parentElement.children[0])}  >Submit</Button>
                                        </Fragment>
                                    } else {
                                        return <Button 
                                        key={answer + i} 
                                        className='ml-2' 
                                        type={currentStep.resultAnswer === answer && `primary`} 
                                        onClick={() => next(answer)}
                                        disabled={block}
                                         >{answer}</Button>
                                    }
                                })
                                }
                            </div>
                        </div>
                        {
                            currentPath.length !== 0 && !questionSaveHandler &&
                            <div className="steps-action">

                                <Button type="primary" onClick={() => next()}>
                                    Next
                                </Button>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Previous
                                </Button>

                            </div>
                        }
                    </div>
                </div>
                <ToastContainer />
            </> : null
    );
};

export default QuestionDisplayInterface;