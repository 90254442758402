import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { Button, Input, Collapse, Checkbox } from 'antd';
import { useHistory } from "react-router";
import './index.css';
import creService from "../../services/creService";
import QuestionDisplayInterface from "./questionsBlocks";

const { Panel } = Collapse;

const getCurrentStep = (path, questions) => {
    let finishStep
    for (let key of path) {
        if (finishStep) {
            finishStep = finishStep[key]
        } else {
            finishStep = questions[key]
        }
    }
    return finishStep
}

const QuestionsBlock = ({ block, path, addAnswer, addQuestion, refresh }) => {

    return <div >
        <Button className='mb-2' onClick={() => { addQuestion(path) }}>
            Add Question
        </Button>
        <Collapse collapsible="icon">
            {
                block.map((blockQuestion, iq) => {
                    return <Panel header={
                        <div onClick={(event) => event.stopPropagation()}>
                            <strong>Question ({iq + 1}):</strong>
                            <Input className='m-2'
                                style={{ width: '600px' }}
                                value={blockQuestion.question}
                                onChange={(e) => { blockQuestion.question = e.currentTarget.value; refresh() }}

                            />
                            <i
                                className="fa fa-trash"
                                onClick={(e) => { block.splice(iq, 1); refresh() }}
                            />
                        </div>
                    } key={path + `Question${iq + 1}`}
                    >
                        <Collapse collapsible="icon">
                            {blockQuestion.answers.map((answer, i) => {
                                return <Panel header={
                                    <div onClick={(event) => event.stopPropagation()}>
                                        <strong>Answer ({i + 1}):</strong>
                                        <Input
                                            style={{ width: '600px' }}
                                            className='m-2'
                                            value={answer}
                                            onChange={(e) => { blockQuestion.answers[i] = e.currentTarget.value; refresh() }}
                                            disabled={answer === 'client input' || answer === 'client date'}
                                        />
                                        <i
                                            className="fa fa-trash"
                                            onClick={(e) => { blockQuestion.answers.splice(i, 1); refresh() }}
                                        />
                                        <div className='mb-1'>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] !== 'client date' &&
                                                    blockQuestion.answers[i] !== 'client input' &&
                                                    blockQuestion.answers[i] !== 'client upload' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'new answer'; refresh() }}>
                                                Preload Answer
                                            </button>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] === 'client date' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'client date'; refresh() }}>
                                                Date picker
                                            </button>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] === 'client input' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'client input'; refresh() }}>
                                                Free Input
                                            </button>
                                            <button
                                                className={`btn m-1 btn-sm btn-light ${blockQuestion.answers[i] === 'client upload' ? 'active' : ''}`}
                                                onClick={(e) => { blockQuestion.answers[i] = 'client upload'; refresh() }}>
                                                File Upload
                                            </button>
                                        </div>
                                    </div>
                                }
                                    key={path + `Answer${i + 1}`}
                                >

                                    {
                                        blockQuestion[answer + 'Sub'] ?
                                            <QuestionsBlock refresh={refresh} block={blockQuestion[answer + 'Sub']} path={[...path, iq, answer + 'Sub']} addAnswer={addAnswer} addQuestion={addQuestion} /> :
                                            <Button className='mb-2' onClick={() => { addQuestion([...path, iq, answer + 'Sub']) }}>
                                                Add Question
                                            </Button>
                                    }
                                </Panel>
                            })}


                        </Collapse>

                        <Button className='mt-2' onClick={() => { addAnswer([...path, iq, 'answers']) }}>
                            Add Answer
                        </Button>
                    </Panel>
                })
            }

        </Collapse>
    </div>
}

const App = ({ location }) => {
    const [adminjwt, setAdminjwt] = useState('');
    const [questions, setQuestions] = useState();


    const [shouldUpdate, setShouldUpdate] = useState(false);
    const history = useHistory()

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);

        let token = queryParams.get('token')

        if (token) {
            localStorage.setItem('adminjwt', JSON.stringify(token));
        } else {
            token = localStorage.getItem('adminjwt');
        }
        history.replace({
            search: '',
        })
        setAdminjwt(token)

        getQuestinary(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (shouldUpdate) {
            const delayDebounceFn = setTimeout(() => {
                updateQuestinary(questions)
            }, 2000);

            return () => clearTimeout(delayDebounceFn);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions]);

    const getQuestinary = async (token) => {
        try {
            const result = await creService.getInspectionQuestioners(token)
            if (!result.data.status) {
                return toast.error(result.data.message);
            }
            setQuestions(result.data.data)
            setShouldUpdate(true)
        } catch (err) {
            toast.error(err.message);
        }
    }

    const updateQuestinary = async () => {

        try {
            const result = await creService.updateInspectionQuestioners(questions, adminjwt)
            if (!result.data.status) {
                return toast.error(result.data.message);
            }
        } catch (err) {
            toast.error(err.message);
        }

    }

    const addQuestion = (path) => {

        const currentArray = getCurrentStep(path, questions)
        if (currentArray) {
            currentArray.push({
                question: 'New question',
                answers: [],
            })
        } else {
            const privious = getCurrentStep(path.slice(0, -1), questions)
            privious[path[path.length - 1]] = [{
                question: 'New question',
                answers: [],
            }]
        }
        setQuestions([...questions]) // to trigger update

    };
    const addAnswer = (path) => {
        const currentArray = getCurrentStep(path, questions)
        if (currentArray) {
            currentArray.push('new answer')
        } else {
            const privious = getCurrentStep(path.slice(0, -1), questions)
            privious[path[path.length - 1]] = ['new answer']
        }
        setQuestions([...questions]) // to trigger update

    };
    const refresh = () => {
        setQuestions([...questions]) // to trigger update
    }


    return (
        questions ?
            <>
                <div>
                    <h3>Questions Blocks</h3>
                    <Collapse >
                        {questions.map((questionBlock, i) => {
                            return <Panel header={<div onClick={(event) => event.stopPropagation()}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <Input
                                            style={{ width: '600px' }}
                                            className='m-2'
                                            value={questionBlock.blockName}
                                            onChange={(e) => {
                                                questionBlock.blockName = e.target.value
                                                refresh()
                                            }
                                            }
                                        />
                                        <i
                                            className="fa fa-trash"
                                            onClick={(e) => { setQuestions(questions.filter((block, index) => index !== i)) }}
                                        />
                                    </div>

                                    <Checkbox
                                        checked={questions[i].disabledForClient}
                                        onChange={(e) => setQuestions(questions.map((el, index) => index === i ? { ...el, disabledForClient: e.target.checked } : el))}
                                    >
                                        Disabled for clients
                                    </Checkbox>
                                </div>
                            </div>
                            } key={'questionsHeader' + i}>
                                <QuestionsBlock
                                    block={questions[i].questions}
                                    path={[i, 'questions']}
                                    addQuestion={addQuestion}
                                    addAnswer={addAnswer}
                                    refresh={refresh}
                                />
                            </Panel>
                        })}
                    </Collapse>
                    <Button className='mt-2' onClick={() => {
                        setQuestions([...questions, {
                            blockName: 'New Block',
                            questions: [],
                            disabledForClient: true
                        }])
                    }}>
                        Add Question Block
                    </Button>
                </div >
                <div className='d-flex justify-content-center' >
                    <div className='w-50 '>
                        <QuestionDisplayInterface questionBlocks={questions} />
                    </div>
                </div >
            </> : null
    );
};

export default App;