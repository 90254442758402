import React, { useEffect } from 'react';
import TableViolation from '../../../../common/table-violation/tableViolation.jsx';

import { useState } from 'react';
const optionsConfig = {
    fixedColumn: {
        complaint_number: true,
        violation_id: true,
        ticket_number: true,
        unique_key: true,
        violationid: true,
        complaintid: true,
        litigationid: true,
        registrationid: true,
        omoid: true,
        vio_id: true,
        boiler_id: true,
        applicationid: true,
        tr6_no: true,
        DeviceID: true,
        _10_digit_bbl: true,
        job_filing_number: true,
        job__: true,
        acct_id: true,
        cof_num: true,
    },
    links: {
        complaint_number:
            '/violation-details/:record._id',
        violation_id:
            '/violation-details/:record._id',
        ticket_number:
            '/violation-details/:record._id',
        unique_key:
            '/violation-details/:record._id',
        violationid:
            '/violation-details/:record._id',
        complaintid:
            '/violation-details/:record._id',
        litigationid:
            '/violation-details/:record._id',
        registrationid:
            '/violation-details/:record._id',
        omoid:
            '/violation-details/:record._id',
        vio_id:
            '/violation-details/:record._id',
        boiler_id:
            '/violation-details/:record._id',
        applicationid:
            '/violation-details/:record._id',
        tr6_no:
            '/violation-details/:record._id',
        DeviceID:
            '/violation-details/:record._id',
        _10_digit_bbl:
            '/violation-details/:record._id',
        job_filing_number:
            '/violation-details/:record._id',
        job__:
            '/violation-details/:record._id',
        acct_id:
            '/violation-details/:record._id',
        cof_num:
            '/violation-details/:record._id',
    },
}
const RetainedListView = ({ data, noAddress }) => {



    const [dataForTable, setDataForTable] = useState({});



    const loadContent = async () => {
        const dataForTablePrep = {}
        for (let category in data) {
            for (let sub in data[category]) {
                console.log(data[category][sub])
                dataForTablePrep[`${category}/${sub.replace(/__/g, ' ').replace(/_/g, ' ')}`] = {
                    columns: [...(noAddress ? [] : [{ title: 'Address', key: 'address' }]), ...data[category][sub].displayName.map(el => ({ title: el.displayName, key: el.apiField }))],
                    customFieldData: {},
                    category,
                    sub,
                    dataSource: data[category][sub].violations.map(el => ({ ...el, ...el.violationData, chats: [], documents: [], notes: [], retained: true })),
                    options: optionsConfig,
                }
                console.log(data[category][sub].violations.map(el => ({ ...el, ...el.violationData })))
            }
        }
        setDataForTable(dataForTablePrep)
    };

    useEffect(() => {
        loadContent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return <div className="my-building">
        {Object.keys(dataForTable).map((title) => <>

            <div className="d-flex justify-content-between border-bottom mb-3">
                <h4 className="pb-3">{title}</h4>
            </div>

            <TableViolation
                data={dataForTable[title]}
                multiChangeAction={true}
                noCharts={true}
                customFieldsFromViolation={true}
            />
        </>)}
    </div>


}

export default RetainedListView;
